<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { useServices } from '../../../composables/useServices';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import { formatTransactionDate } from '~/utilities/data-transformers';
import GenericError from '~/components/user-interface/generic-error.vue';
import { useSiteStore } from '~/stores/site.store';
import { trackingMethods } from '~/plugins/analytics.client';

export interface ITransactionDetail {
  sequenceId: number;
  id: string;
  transactionTypeId: string;
  transactionTypeDescription: string;
  transactionTypeSourceId: string;
  transactionTypeCategoryId: string;
  accountId: string;
  brandId: string;
  isCredit: boolean;
  amount: 0;
  currencyCode: string;
  balanceIndexAffected: 0;
  internalReferenceId: string;
  externalReference: string;
  financialReference: string;
  createdDateTime: string;
  externalCreatedById: string;
  note: string;
  generalInformation: string;
  login: string;
  maxPayoutLimit: number;
  commitment: number;
  totalWager: number;
  totalPayout: number;
  totalOdds: number;
}

interface ITransactionDetailProps {
  title: string;
  transactionId: string;
}

const { $t } = useNuxtApp();
const { $walletService } = useServices();
const siteStore = useSiteStore();
const props = withDefaults(defineProps<ITransactionDetailProps>(), {
  title: '',
  transactionId: '',
});
const displayLoader = ref(true);
const detailedView = ref<ITransactionDetail>();
const errorMessage = ref('');

async function fetchTransaction() {
  try {
    await $walletService
      .fetchTransactionDetail(props.transactionId)
      .then((data) => {
        displayLoader.value = false;
        if (data?.isSuccessful) {
          errorMessage.value = '';
          detailedView.value = data.data;
        } else {
          console.error(data?.error?.message);
          errorMessage.value = 'an-error-occurred';
        }
      });
  } catch (e) {
    displayLoader.value = false;
    console.error(e);
    errorMessage.value = 'an-error-occurred';
  }
}

onMounted(() => {
  fetchTransaction();
  trackingMethods.myAccountDetailedTransaction();
});

const emit = defineEmits(['back']);
</script>

<template>
  <div>
    <LazyUserAccountSettingsHeader
      :title="`${$t(props.title)} ${
        detailedView?.sequenceId ? '- ' + detailedView?.sequenceId : ''
      }`"
      :return-route="props.title"
      :show-detail="true"
      @close-show-detail="emit('back')"
    />

    <div class="w-full sm:px-2 mt-2">
      <LazyUserInterfaceGenericLoader v-if="displayLoader" :standalone="true" />
      <div
        v-else
        class="table-view border-none sm:border-1 dark:b-dark-500 b-light-600 h-full w-full text-dark-700 dark:text-white flex flex-col sm:rounded-lg text-sm overflow-hidden"
      >
        <div class="flex justify-between p-2 sm:py-3">
          <div class="text-left font-bold">
            {{ $t(`bookmaker`) }}
          </div>
          <div
            class="text-right"
            v-html="$t(`bookmaker-${siteStore.getRegionCode}-detail`)"
          />
        </div>
        <div class="flex justify-between p-2 sm:py-3">
          <div class="text-left font-bold">{{ $t('date') }}</div>
          <div class="text-right">
            {{ formatTransactionDate(new Date(detailedView?.createdDateTime)) }}
          </div>
        </div>
        <div class="flex justify-between p-2 sm:py-3">
          <div class="text-left font-bold">{{ $t('transaction-id') }}</div>
          <div class="text-right">
            {{ detailedView?.sequenceId }}
          </div>
        </div>

        <div class="flex justify-between p-2 sm:py-3" v-if="detailedView?.note">
          <div class="text-left font-bold">{{ $t('game-name') }}</div>
          <div class="text-right">{{ detailedView?.note }}</div>
        </div>
        <div class="flex justify-between p-2 sm:py-3">
          <div class="text-left font-bold">{{ $t('transaction-type') }}</div>
          <div class="text-right">
            {{ detailedView?.transactionTypeDescription }}
          </div>
        </div>
        <div class="flex justify-between p-2 sm:py-3">
          <div class="text-left font-bold">{{ $t('wager-type') }}</div>
          <div class="text-right">
            {{ $t(`wager-type-${siteStore.getRegionCode}`) }}
          </div>
        </div>
        <div class="flex justify-between p-2 sm:py-3">
          <div class="text-left font-bold capitalize">{{ $t('amount') }}</div>
          <div class="flex text-right">
            <div
              class="gold-gradient-text font-bold"
              v-if="!detailedView?.isCredit"
            >
              -&nbsp;
            </div>
            <div v-html="useFormatCurrency(detailedView?.amount)" />
          </div>
        </div>
        <div class="flex justify-between p-2 sm:py-3">
          <div class="text-left font-bold">{{ $t('address') }}</div>
          <div class="text-right">
            {{ $t(`address-${siteStore.getRegionCode}`) }}
          </div>
        </div>
      </div>
      <GenericError v-if="errorMessage" state="danger" class="h-fit my-3">
        {{ $t(errorMessage) }}
      </GenericError>
    </div>
    <div class="px-3 py-2 mt-2">
      <Button
        element-name="detailed-transaction-back-button"
        class="w-full flex justify-center detailed-transactions mt-auto"
        group="secondary"
        @click="emit('back')"
        :label="$t(`back`)"
      >
        <ChevronLeftIcon class="w-5 mr-2" style="stroke-width: 3" />
        {{ $t(`back`) }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss">
.button--secondary.p-button:enabled:hover.detailed-transactions {
  background-color: #383f4e;
}
</style>

<style scoped lang="scss">
.table-view > div:nth-child(even) {
  background-color: #eceff6;
}
.table-view > div:nth-child(odd) {
  background-color: #fff;
}

.dark {
  .table-view > div:nth-child(even) {
    background-color: #2b303b;
  }
  .table-view > div:nth-child(odd) {
    background-color: #1d2129;
  }
}
</style>
